import loadable from '@loadable/component'
import React from 'react'

import FooterMenu from '../components/FooterMenu'
import Header from '../components/Header'
import Layout from '../components/Layout'
import PageContent from '../components/PageContent'
import SEO from '../components/SEO'

const LoadableFAQ = loadable(() => import('../components/FAQ'))

function FAQPage() {
  return (
    <Layout>
      <SEO title='FAQ' />
      <PageContent>
        <Header />
        <LoadableFAQ />
        <FooterMenu />
      </PageContent>
    </Layout>
  )
}

export default FAQPage
